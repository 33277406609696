@charset "UTF-8";
@import './../base-bootstrap';

$bgcolor: var(--pat-theme-back, $white);

[data-pd-component-type='bits.productCategoryTabsLink']:empty {
    display: none;
}

.product-category-tabs {
    background-color: var(--pat-theme-back, transparent);

    @include mq('medium', 'max') {
        padding: 0;

        overflow-x: hidden;
    }

    .swiper-button-prev,
    .swiper-button-next {
        @include DropShadow(4);
        position: absolute;
        top: 50%;
        z-index: 3;

        display: none;
        align-content: center;
        align-items: center;

        width: $spacer * 4;
        height: $spacer * 4;
        margin: 0;
        padding: 0;

        border-radius: $spacer * 4;

        background: $white;

        opacity: 0;
        transform: translateY(-50%);

        pointer-events: none;

        transition: opacity 0.5s $ease-in-out-soft;

        @include mq('medium') {
            display: inline-flex;
        }

        svg,
        figure {
            width: 1.3rem;
            height: 1.3rem;
        }

        svg {
            vertical-align: top;
        }
    }

    .swiper-button-prev {
        left: 0;

        figure {
            margin-right: 2px;
        }

        svg {
            transform: rotate(180deg);
        }
    }

    .swiper-button-next {
        right: 0;

        figure {
            margin-left: 2px;
        }
    }

    &--can-scroll-left {
        .product-category-tabs__wrapper {
            &:before {
                opacity: 1;
            }

            .swiper-button-prev {
                opacity: 1;

                pointer-events: all;
            }
        }

        .product-category-tabs__links {
            @include mq('medium') {
                border-bottom: none;
            }
        }
    }

    &--can-scroll-right {
        .product-category-tabs__wrapper {
            &:after {
                opacity: 1;
            }

            .swiper-button-next {
                opacity: 1;

                pointer-events: all;
            }
        }

        .product-category-tabs__links {
            @include mq('medium') {
                border-bottom: none;
            }
        }
    }

    &__wrapper {
        position: relative;

        &:before,
        &:after {
            position: absolute;
            top: 0;
            bottom: 0;
            content: '';
            z-index: 2;

            display: none;

            width: 72px;

            background-color: $bgcolor;

            opacity: 0;

            pointer-events: none;

            transition: opacity 0.5s $ease-in-out-soft;

            @include mq('medium') {
                display: block;
            }
        }

        &:before {
            left: 0;

            mask-image: linear-gradient(to right, rgba($black, 1), rgba($black, 0));
        }

        &:after {
            right: 0;

            mask-image: linear-gradient(to left, rgba($black, 1), rgba($black, 0));
        }
    }

    &__links {
        position: relative;

        display: flex;

        padding: 0;

        white-space: nowrap;

        border-bottom: 1px solid var(--neutral-grey, #999);

        overflow: auto;
        overflow-y: hidden;

        -webkit-overflow-scrolling: touch;
        overscroll-behavior-x: contain;
        scroll-snap-type: x mandatory;

        // Standardized properties for FF
        @supports not selector(::-webkit-scrollbar) {
            scrollbar-color: color-mix(
                    in srgb,
                    var(--pat-theme-back, $black),
                    var(--pat-theme-fore, $white) 60%
                )
                transparent;
            scrollbar-width: thin;
        }

        &:empty {
            display: none;
        }

        &::-webkit-scrollbar {
            width: $spacer * 0.5;
            height: $spacer * 0.5;
        }

        &::-webkit-scrollbar:horizontal {
            height: $spacer * 0.5;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: $spacer;

            background-color: color-mix(
                in srgb,
                var(--pat-theme-back, $black),
                var(--pat-theme-fore, $white) 60%
            );
        }

        &::-webkit-scrollbar-track {
            border-radius: $spacer;

            background: transparent;
        }
    }

    li {
        display: inline-block;

        margin-left: $spacer * 0.5;

        &:first-child {
            margin-left: $spacer;

            @include mq('medium') {
                margin-left: 0;
            }
        }
    }

    &__link {
        @include p;
        display: block;

        padding: $spacer;

        white-space: nowrap;
        scroll-snap-align: start;

        border-bottom: 0.3rem solid transparent;

        @media (prefers-reduced-motion: no-preference) {
            transition: border-color 0.5s $ease-in-out-soft;
        }

        // quirk with last child and scroll snap
        .product-category-tabs li:last-child & {
            padding-right: $spacer * 2;

            @include mq('medium') {
                padding-left: $spacer;
            }
        }

        &--active {
            font-weight: $font-weight-medium;

            border-bottom-color: currentColor;
        }
    }
}
